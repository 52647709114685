<script setup>


import DocumentSearch from '@/partials/DocumentSearch.vue'
import DocumentInfo from '@/partials/DocumentInfo.vue'
import DocumentInfoList from '@/partials/DocumentInfoList.vue'
import FormBoirSection from '@/partials/Products/FormBoirSection.vue'
import FormEinSection from '@/partials/Products/FormEinSection.vue'
import {defineEmits, onMounted, onBeforeUnmount, watch, reactive, toRaw, ref, computed} from "vue";
import {useForm} from "laravel-precognition-vue-inertia";
import axios from 'axios';
import {TrashIcon} from "@heroicons/vue/20/solid";
import {VueSignaturePad} from 'vue-signature-pad';
import {integer} from "@vuelidate/validators";
import moment from 'moment'


const props = defineProps({
  documentProp: {
    type: Object,
    required: false,
    default: null,
  },
  jurisdictionProp: {
    type: Object,
    required: true,
    default: null,
  },
  companyNameProp: {
    type: Object,
    required: false,
    default: null,
  },
  productProp: {
    type: Object,
    required: false,
    default: null,
  },

})

const emits = defineEmits(['update-products']);

const companyName = ref(null);
const companyDocument = ref(props.documentProp);
const jurisdiction = ref(null);
const product = ref(props.productProp);
const documentItem = ref(null);
const children = ref([]);
const finished = ref(false);
const tos = ref(null);
const loading = ref(false);

const selectedItems = ref([]);
const availableItems = ref([]);
const errorsPresent = ref(false);


const total = computed(() => {
  let calculatedTotal = 0;

  for (let i = 0; i < selectedItems.value.length; i++) {
    calculatedTotal = Number(calculatedTotal) + Number(selectedItems.value[i].price);

  }
  return calculatedTotal;

});

function hasItem(code) {

  for (let i = 0; i < availableItems.value.length; i++) {
    if (selectedItems.value[i] && selectedItems.value[i].code === code) {
      return true;
    }
  }
  return false;

}

function addItems(index) {

  if (!isItemAlreadyInCart(index)) {
    selectedItems.value.push({
      id: availableItems.value[index].id,
      uuid: availableItems.value[index].uuid,
      title: availableItems.value[index].name,
      code: availableItems.value[index].code,
      href: '#',
      price: availableItems.value[index].price,
      priceId: availableItems.value[index].priceId,
      subTitle: companyName.value,
      description: availableItems.value[index].description,
      image: availableItems.value[index].image,
      imageAlt: availableItems.value[index].name,
      isPrimary: true,
    })
  }


}


function isItemAlreadyInCart(index) {

  for (let i = 0; i < selectedItems.value.length; i++) {

    if (selectedItems.value[i].code === availableItems.value[index].code) {
      return true;
    }
  }
  return false;

}

function deleteItem(code) {

  for (let i = 0; i < selectedItems.value.length; i++) {
    if (selectedItems.value[i].code === code) {
      selectedItems.value.splice(i, 1);
    }
  }
}

async function getProducts() {

  let productCode = null;
  let documentUuid = null;

  if (product.value && product.value.code) {
    productCode = product.value.code;
  }


  await axios
      .post(route('public.api.get.products'), {
        product_code: productCode,
        jurisdiction_uuid: props.jurisdictionProp.uuid
      })
      .then(response => {
        availableItems.value = response.data.data;
      })
  for (let i = 0; i < availableItems.value.length; i++) {

    if (availableItems.value[i].code === product.value) {
      availableItems.value[i].isPrimary = true;
    }
    if (availableItems.value[i].isPrimary === true) {
      addItems(i);
      emits('update-products', {'availableItems': availableItems.value, 'selectedItems': selectedItems.value});
    }

  }


}


const submit = () => {
  console.log('SUBMITTED');
  // Scroll to top after form submission
  window.scrollTo({
    top: 0,
    behavior: 'smooth' // for smooth scrolling
  });
  emits('update-products', {'availableItems': availableItems.value, 'selectedItems': selectedItems.value});

};

const formattedDate = (date) => {
  moment(date).format('MMMM DD, YYYY');
}


onMounted(() => {
  console.log(' Product Selection jurisdictionProp');
  console.log(product.value);

  if (props.companyNameProp) {
    companyName.value = props.companyNameProp;
  } else if (props.documentProp) {
    companyName.value = props.documentProp.company.name;
  }

  getProducts();

});
</script>

<template>

  <div class="mx-auto max-w-2xl px-4 pb-24 pt-16 sm:px-6 lg:max-w-7xl lg:px-8">
    <div>
      <form @submit.prevent="submit" class="lg:grid lg:grid-cols-2 lg:gap-x-12 xl:gap-x-16">
        <div>

          <div
              v-if="props.documentProp"
              class="p-4 mx-auto max-w-2xl px-4 pb-2pt-2 sm:px-6 lg:max-w-xl lg:px-8 mt-4 rounded-lg border border-indigo-300 bg-gray-100">
            <div class="mt-2 border-t border-gray-100">
              <dl class="divide-y divide-gray-100">
                <div class="px-4 py-1 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-0">
                  <dt class="text-sm font-medium leading-6 text-gray-900">Company Name:</dt>
                  <dd class="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-1 sm:mt-0">
                    <span class="flex-grow">{{props.documentProp.company.name}}</span>

                  </dd>
                </div>
                <div class="px-4 py-1 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-0">
                  <dt class="text-sm font-medium leading-6 text-gray-900">Company Type:</dt>
                  <dd class="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-1 sm:mt-0">
                    <span class="flex-grow">{{props.documentProp.company.type.name}}</span>

                  </dd>
                </div>
                <div class="px-4 py-1 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-0">
                  <dt class="text-sm font-medium leading-6 text-gray-900">Document Number:</dt>
                  <dd class="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-1 sm:mt-0">
                    <span class="flex-grow">{{props.documentProp.document_number}}</span>

                  </dd>
                </div>
                <div class="px-4 py-1 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-0">

                  <dt class="text-sm font-medium leading-6 text-gray-900">Notice Date:</dt>
                  <dd class="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-1 sm:mt-0">
                    <span class="flex-grow">{{props.documentProp.notice_date}}</span>

                  </dd>
                </div>
                <div class="px-4 py-1 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-0">
                  <dt class="text-sm font-medium leading-6 text-gray-900">Address:</dt>
                  <div><dd class="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-1 sm:mt-0">
                    <span class="flex-grow">{{props.documentProp.company.addresses[0].address}}</span>
                  </dd>
                    <dd class="mt-1 flex text-sm leading-6 text-gray-700 sm:col-span-1 sm:mt-0">
                      <span class="flex-grow">{{props.documentProp.company.addresses[0].city}}, {{props.documentProp.company.addresses[0].state}} {{props.documentProp.company.addresses[0].zip}} </span>
                    </dd>
                  </div>
                </div>
              </dl>
            </div>
          </div>









          <!-- Order summary -->
          <div class="mt-10 lg:mt-0">
            <h2 class="text-lg font-medium text-gray-900 uppercase"
                :class="documentProp ? 'mt-4' : ''"
            >Select From Services</h2>
            <div class="mt-4 rounded-lg border border-gray-200 bg-white shadow-sm">
              <h3 class="sr-only">Items in your cart</h3>
              <ul role="list" class="divide-y divide-gray-200">
                <li v-for="(product,index) in availableItems" :key="index" class="flex px-4 py-6 sm:px-6">
                  <div class="flex-shrink-0">
                    <img :src="'/images/products/'+product.image" :alt="product.imageAlt" class="w-20 rounded-md"/>
                  </div>
                  <div class="ml-6 flex flex-1 flex-col">
                    <div class="flex">
                      <div class="min-w-0 flex-1">
                        <h4 class="text-sm">
                          <a :href="product.href" class="font-medium text-gray-700 hover:text-gray-800">{{
                              product.name
                            }}</a>
                        </h4>
                        <p class="mt-1 text-sm text-gray-500">{{ companyName }}</p>
                        <p class="mt-1 text-sm text-gray-500">{{ product.description }}</p>
                      </div>

                      <div class="ml-4 flow-root flex-shrink-0">
                        <div class="mt-5">


                          <button v-if="isItemAlreadyInCart(index)" type="button"
                                  class="-m-2.5 flex items-center justify-center bg-white p-2.5 text-gray-400 hover:text-gray-500">
                            <span class="sr-only">Remove</span>
                            <TrashIcon @click="deleteItem(product.code)" class="h-5 w-5" aria-hidden="true"/>
                          </button>
                          <button v-else @click="addItems(index)" type="button"
                                  class="inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500">
                            Add
                          </button>
                        </div>
                      </div>
                    </div>


                    <div class="flex flex-1 items-end justify-between pt-2">
                      <p class="mt-1 text-sm font-medium text-gray-900">$ {{ product.price }}</p>
                    </div>
                  </div>

                </li>

              </ul>

            </div>
          </div>

        </div>

        <!-- Order summary -->
        <div class="mt-10 lg:mt-0">
          <h2 class="text-lg font-medium text-gray-900 uppercase">Order Summary</h2>

          <div class="mt-4 rounded-lg border border-gray-200 bg-white shadow-sm">
            <h3 class="sr-only">Items in your cart</h3>
            <ul role="list" class="divide-y divide-gray-200">
              <li v-for="(product,index) in selectedItems" :key="index" class="flex px-4 py-6 sm:px-6">
                <div class="flex-shrink-0">
                  <img :src="'/images/products/'+product.image" :alt="product.imageAlt" class="w-20 rounded-md"/>
                </div>

                <div class="ml-6 flex flex-1 flex-col">
                  <div class="flex">
                    <div class="min-w-0 flex-1">
                      <h4 class="text-sm">
                        <a :href="product.href" class="font-medium text-gray-700 hover:text-gray-800">{{
                            product.title
                          }}</a>
                      </h4>
                      <p class="mt-1 text-sm text-gray-500">{{ product.subTitle }}</p>
                      <p class="mt-1 text-sm text-gray-500">{{ product.description }}</p>
                    </div>
                    <div class="ml-4 flow-root flex-shrink-0">
                      <button type="button"
                              class="-m-2.5 flex items-center justify-center bg-white p-2.5 text-gray-400 hover:text-gray-500">
                        <span class="sr-only">Remove</span>
                        <TrashIcon @click="deleteItem(product.code)" class="h-5 w-5" aria-hidden="true"/>
                      </button>
                    </div>
                    <div v-if="!product.isPrimary" class="ml-4 flow-root flex-shrink-0">
                      <button type="button"
                              class="-m-2.5 flex items-center justify-center bg-white p-2.5 text-gray-400 hover:text-gray-500">
                        <span class="sr-only">Remove</span>
                        <TrashIcon @click="deleteItem(product.code)" class="h-5 w-5" aria-hidden="true"/>
                      </button>
                    </div>
                  </div>


                  <div class="flex flex-1 items-end justify-between pt-2">
                    <p class="mt-1 text-sm font-medium text-gray-900">$ {{ product.price }}</p>
                  </div>
                </div>
              </li>

            </ul>
            <div class="bg-emerald-500 text-white p-1 text-center mb-2 uppercase">Others Also Purchased</div>

            <div v-for="(child,index) in availableItems" :key="index" class="flex text-sm ml-4 mb-4 text-indigo-600">
              <button v-if="!hasItem(child.code)" @click="addItems(index)" class="flex">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                     stroke="currentColor" class="w-6 h-6 mr-2">
                  <path stroke-linecap="round" stroke-linejoin="round"
                        d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"/>
                </svg>
                <span v-if="child.code=='ein_registration' || child.code=='boir'" class="font-bold text-red-500 mr-1">CRITICAL: </span>
                Add {{ child.name }} + ${{ child.price }}

              </button>
            </div>

            <dl class="space-y-6 border-t border-gray-200 px-4 py-6 sm:px-6">
              <div class="flex items-center justify-between">
                <dt class="text-sm">Subtotal</dt>
                <dd class="text-sm font-medium text-gray-900">$ {{ total.toFixed(2) }}</dd>
              </div>
              <div class="flex items-center justify-between">
                <dt class="text-sm">Shipping</dt>
                <dd class="text-sm font-medium text-gray-900">$0.00</dd>
              </div>
              <div class="flex items-center justify-between">
                <dt class="text-sm">Taxes</dt>
                <dd class="text-sm font-medium text-gray-900">$0.00</dd>
              </div>
              <div class="flex items-center justify-between border-t border-gray-200 pt-6">
                <dt class="text-base font-medium">Total</dt>
                <dd class="text-base font-medium text-gray-900">$ {{ total.toFixed(2) }}</dd>
              </div>
            </dl>

            <div class="border-t border-gray-200 px-4 py-6 sm:px-6">
              <button type="button"
                      :class="{
        'inline-flex justify-center text-xl text-center uppercase w-full items-center rounded-md px-4 py-3 font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2': true,
        'bg-indigo-600 text-white hover:bg-indigo-500 focus-visible:outline-indigo-600': (selectedItems.length > 0),
        'bg-gray-300 text-gray-500  cursor-not-allowed': selectedItems.length === 0
    }"
                      @click="submit"
                      :disabled=" selectedItems.length === 0">
                <span>Continue</span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>

  </div>
</template>

