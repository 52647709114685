<script setup>

import Header from '../../partials/Header.vue'
import OrderSection from "../../partials/OrderSection.vue";
import ProductSelection from "../../partials/ProductSelection.vue";
import SelectJurisdiction from "../../partials/SelectJurisdiction.vue";


import Footer from '../../partials/Footer.vue'
import {onBeforeMount, onMounted, reactive, ref} from "vue";
import {usePage} from "@inertiajs/inertia-vue3";
import Steps from '@/Components/Steps.vue';


const props = defineProps({
  documentProp: {
    type: Object,
    required: false,
    default: null
  },
  jurisdictionProp: {
    type: Object,
    required: false,
    default: null
  },
  productProp: {
    type: Object,
    required: false,
    default: null
  },
  brandInfo: {
    type: Object,
    required: true,
    default: null
  },
  loading: {
    type: Boolean,
    required: false,
    default: false
  },

})
const currentStep = ref(usePage().props.value.documentProp ? 2 : 1);

const document = ref(usePage().props.value.documentProp);

const jurisdictionIdentifier = ref(null);
const jurisdictionUuid = ref(usePage().props.value.jurisdictionProp);
const jurisdictionName = ref(null);

const companyName = ref(null);
const availableItems = ref(null);
const selectedItems = ref(null);

const product = ref(usePage().props.value.productProp);


const updateCurrentStep = (step) => {
  currentStep.value = step;
}

const updateProducts = (products) => {
  currentStep.value = 3;
  availableItems.value = products.availableItems;
  selectedItems.value = products.selectedItems;

}


const getJurisdiction = (data) => {
  jurisdictionIdentifier.value = data.identifier;
  jurisdictionUuid.value = data.uuid;
  jurisdictionName.value = data.jurisdictionName;
  companyName.value = data.companyName;
  updateCurrentStep(2);

}

onBeforeMount(() => {

  if (usePage().props.value.documentProp) {
    document.value = usePage().props.value.documentProp;
    updateCurrentStep(2);
  }


  if (usePage().props.value.jurisdictionProp) {
    jurisdictionUuid.value = usePage().props.value.jurisdictionProp;
    updateCurrentStep(1);
  }

  if (usePage().props.value.documentProp) {
    jurisdictionUuid.value = usePage().props.value.jurisdictionProp;
    // companyName.value = document.company.name;
    updateCurrentStep(2);
  }

  if (usePage().props.value.productProp) {
    product.value = usePage().props.value.productProp;
  }

});

</script>

<template>

  <div class="flex flex-col overflow-hidden">
    <!-- Site header -->
    <Header :brandInfo="brandInfo" class="min-h-[125px]"/>
    <!-- Page content -->
    <main class="grow">

      <Steps :currentStepProp="currentStep" @update-current-step="updateCurrentStep"></Steps>
      <div class="bg-gray-50">
        <div v-if="currentStep == 1">
          <SelectJurisdiction :jurisdictionProp="jurisdictionUuid"
                              @select-jurisdiction="getJurisdiction"></SelectJurisdiction>
        </div>
        <div v-if="currentStep == 2">
          <ProductSelection :jurisdictionProp="jurisdictionUuid"
                            :documentProp="documentProp"
                            :productProp="product"
                            :companyNameProp="companyName"
                            @update-products="updateProducts"></ProductSelection>
        </div>
        <div v-if="currentStep == 3">
          <OrderSection :brandInfoProp="brandInfo"
                        :jurisdictionProp="jurisdictionUuid"
                        :documentProp="documentProp"
                        :companyNameProp="companyName"
                        :selectedItemsProp="selectedItems"
                        :availableItemsProp="availableItems"></OrderSection>
        </div>
      </div>
    </main>
    <!-- Site footer -->
    <Footer :brandInfo="brandInfo"/>
  </div>
</template>

