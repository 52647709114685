<script setup>


import DocumentSearch from '@/partials/DocumentSearch.vue'
import DocumentInfo from '@/partials/DocumentInfo.vue'
import DocumentInfoList from '@/partials/DocumentInfoList.vue'
import {defineEmits, onMounted, reactive, ref, watch} from "vue";
import {useForm, usePage} from "@inertiajs/inertia-vue3";
import axios from 'axios';
import Dropdown from '@/Components/Form/Dropdown.vue'

const emits = defineEmits(['select-jurisdiction']);

const props = defineProps({
  jurisdictionProp: {
    type: String,
    required: false,
    default: null
  },
  loading: {
    type: Boolean,
    required: false,
    default: false
  },
})

const loading = ref(false)
const jurisdictionList = ref([])
const jurisdictionSelected = ref(null)
const companyName = ref(null)
const errorText = ref(null)


const getJurisdictionInfo = async () => {
  loading.value = true;
  await axios
      .post(route('public.api.jurisdiction.list'))
      .then(response => {
        jurisdictionList.value = response.data.data;
      })
  loading.value = false;
}

const updateJurisdiction = () => {

  if (jurisdictionSelected.value !== null) {
    emits('select-jurisdiction', {
      uuid: jurisdictionSelected.value,
      companyName: companyName.value
    });
  } else {
    errorText.value = 'You must select a State of Incorporation.';
  }

}

const hasJurisdiction = () => {
  if (jurisdictionSelected.value) {
    return true;
  }
  return false;
}


const selectPredeterminedJurisdiction = async () => {
  console.log('selectPredeterminedJurisdiction');
  console.log(props.jurisdictionProp);
  console.log(jurisdictionList.value);
  if (props.jurisdictionProp) {
    for (let i = 0; i < jurisdictionList.value.length; i++) {
      console.log(jurisdictionList.value[i].uuid);
      console.log(props.jurisdictionProp);
      console.log('---');
      if (jurisdictionList.value[i].uuid === props.jurisdictionProp) {
        jurisdictionSelected.value = jurisdictionList.value[i];
      }
    }

  }

}

const setUpOnMounted = async () => {
  await getJurisdictionInfo();
  errorText.value = null;

  await selectPredeterminedJurisdiction();

}



onMounted(() => {

   setUpOnMounted();

});

</script>

<template>
  <div className="flex flex-col overflow-hidden">
    <div v-if="!jurisdiction">
      <section class="bg-slate-100">
        <div class="relative max-w-6xl mx-auto px-4 sm:px-6">
          <div class="py-12 md:py-20">

            <div class="relative max-w-3xl mx-auto text-center">
              <div class="absolute right-0 -mt-4 -mr-1 fill-slate-300 hidden lg:block" aria-hidden="true">
                <svg class="fill-slate-300" width="56" height="43">
                  <path
                      d="M4.532 30.45C15.785 23.25 24.457 12.204 29.766.199c.034-.074-.246-.247-.3-.186-4.227 5.033-9.298 9.282-14.372 13.162C10 17.07 4.919 20.61.21 24.639c-1.173 1.005 2.889 6.733 4.322 5.81M18.96 42.198c12.145-4.05 24.12-8.556 36.631-12.365.076-.024.025-.349-.055-.347-6.542.087-13.277.083-19.982.827-6.69.74-13.349 2.24-19.373 5.197-1.53.75 1.252 7.196 2.778 6.688"/>
                </svg>
              </div>

              <div class="relative">
                <h2 class="h2 font-playfair-display text-slate-800 mb-4">Enter your<span
                    class="text-emerald-500"> Company Information</span></h2>
                <p>In the form below enter your company name and the state that your company or organization was
                  incorporated in.</p>
                <div>

                  <div class="sm:col-span-4  mt-10 mb-2">
                    <label for="companyName" class="block text-lg font-medium leading-6 text-gray-600">Enter Your
                      Company Name</label>
                  </div>
                  <div class="mx-auto flex max-w-md gap-x-4 ">
                    <input placeholder="Your Company Name Here" v-model="companyName" id="companyName"
                           name="companyName" type="text"
                           class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6">


                  </div>


                  <div class="sm:col-span-4  mt-10 mb-2">
                    <label for="jurisdiction" class="block text-lg font-medium leading-6 text-gray-600">State of
                      Incorporation</label>
                  </div>
                  <div class="mx-auto flex max-w-md gap-x-4 ">
                    <select id="jurisdiction" name="jurisdiction" v-model="jurisdictionSelected"
                            class="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            aria-controls="options" aria-expanded="false">
                      <option value="" disabled selected>Select a State</option>
                      <option v-for="jurisdiction in jurisdictionList" :key="jurisdiction.uuid"
                              :value="jurisdiction">
                        {{ jurisdiction.name }}
                      </option>
                    </select>

                  </div>
                  <div v-if="errorText">{{ errorText }}</div>

                  <button @click="updateJurisdiction" type="submit"
                          class="mt-5 inline-flex items-center justify-center rounded-md bg-indigo-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-emerald-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white">
  <span v-if="loading" class="mr-2">
    <svg aria-hidden="true" class="w-5 h-5 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
         viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"/>
      <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"/>
    </svg>
  </span>
                    <span>CONTINUE</span>
                  </button>
                </div>
              </div>

            </div>

          </div>
        </div>
      </section>
    </div>


  </div>
</template>

